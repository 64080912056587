.darkblue{
    background-color: darkblue;
}

.home-page{
    .total-cases{
        list-style: none;
        li span{
            color:darkblue;
            min-width: 154px;
            display: inline-block;
            margin-right: 10px;
        }
    }

    .refresher{
        background-color: white;        
    }
}