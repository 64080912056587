.health-care-list{
    list-style: none;

    &--item{
        padding: 10px 0;
        border-bottom: 1px solid lightgray;
        display: flex;

        img{
            width: 60px;
            height: 60px;
            border-radius: 10px;
            margin: 0 5px;
        }

        .prevention{
            font-size: 14px;
            color: gray;
        }
    }
}