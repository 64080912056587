.list-component {
  .search-bar {
    background: white;
    border: 2px solid darkgray;
    border-radius: 10px;
    padding: 5px 10px !important;
  }

  .divider {
    background: darkgray;
    margin: 10px 0;
  }

  .countries-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      width: 90%;
      height: 100px;
      border: 2px solid darkgrey;
      border-radius: 10px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 2px 25px -18px rgba(0, 0, 0, 1);

      div {
        &.country {
          width: 100%;
          padding: 10px 0;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          font-size: 18px;
          border-bottom: 1px solid darkgray;
          color: gray;
          display: flex;
          justify-content: center;

          img {
            width: 30px;
            margin-right: 10px;
          }
        }
        &.total-cases {
          width: 100%;
          margin-top: 5px;
          display: flex;
          justify-content: center;
          font-size: 15px;
          color: darkgray;
        }
        &.status {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            padding-left: 15px;

            &:first-child {
              padding: 0;
            }

            &.confirmed {
              color: gray;
            }

            &.recovered {
              color: limegreen;
            }

            &.death {
              color: #eb445a;
            }
          }
        }
      }
    }
  }
}
