@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #083D77;
  --ion-color-primary-rgb: 8,61,119;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #073669;
  --ion-color-primary-tint: #215085;

  --ion-color-secondary: #03B5AA;
  --ion-color-secondary-rgb: 3,181,170;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #039f96;
  --ion-color-secondary-tint: #1cbcb3;

  --ion-color-tertiary: #023436;
  --ion-color-tertiary-rgb: 2,52,54;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #022e30;
  --ion-color-tertiary-tint: #1b484a;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #01161E;
  --ion-color-dark-rgb: 1,22,30;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #01131a;
  --ion-color-dark-tint: #1a2d35;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* @media (prefers-color-scheme: light) { */
  /*
   * Dark Colors
   * -------------------------------------------
   */

  /* body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  } */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #1c1c1c;
    --ion-item-background-activated: #313131;
  } */


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1A1B1E;
  }

  ion-title.title-large {
    --color: white;
  }
} */

*{margin:0;padding:0;font-family:"Roboto",sans-serif}.text-center{text-align:center}.co-container{width:90%;margin:0 auto}

.list-component .search-bar{background:white;border:2px solid darkgray;border-radius:10px;padding:5px 10px !important}.list-component .divider{background:darkgray;margin:10px 0}.list-component .countries-list{list-style:none;display:flex;flex-wrap:wrap;justify-content:center}.list-component .countries-list li{width:90%;height:100px;border:2px solid darkgrey;border-radius:10px;margin:5px;display:flex;flex-direction:column;align-items:center;box-shadow:0px 2px 25px -18px #000}.list-component .countries-list li div.country{width:100%;padding:10px 0;margin-bottom:5px;display:flex;align-items:center;font-size:18px;border-bottom:1px solid darkgray;color:gray;display:flex;justify-content:center}.list-component .countries-list li div.country img{width:30px;margin-right:10px}.list-component .countries-list li div.total-cases{width:100%;margin-top:5px;display:flex;justify-content:center;font-size:15px;color:darkgray}.list-component .countries-list li div.status{width:100%;display:flex;align-items:center;justify-content:center}.list-component .countries-list li div.status span{padding-left:15px}.list-component .countries-list li div.status span:first-child{padding:0}.list-component .countries-list li div.status span.confirmed{color:gray}.list-component .countries-list li div.status span.recovered{color:limegreen}.list-component .countries-list li div.status span.death{color:#eb445a}

.darkblue{background-color:darkblue}.home-page .total-cases{list-style:none}.home-page .total-cases li span{color:darkblue;min-width:154px;display:inline-block;margin-right:10px}.home-page .refresher{background-color:white}

.about-page>div{height:100%}.about-page>div .copy{margin-top:20px}

.health-page .title{margin-bottom:15px;font-size:26px}

.health-care-list{list-style:none}.health-care-list--item{padding:10px 0;border-bottom:1px solid lightgray;display:flex}.health-care-list--item img{width:60px;height:60px;border-radius:10px;margin:0 5px}.health-care-list--item .prevention{font-size:14px;color:gray}

p{font-size:12px;margin-bottom:10px;color:darkgray}

.success{color:limegreen}.danger{color:#eb445a}

