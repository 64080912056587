
*{
    margin: 0;
    padding: 0;
    font-family: $Roboto;    
}

.text-center{
    text-align: center;
}

.co-container{
    width: 90%;
    margin: 0 auto;
}